<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts">
  export default {
    data(): {} {
      return {};
    }
  };
</script>

<style lang="less" scoped></style>
