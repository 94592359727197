import { render, staticRenderFns } from "./TrademarkBanner.vue?vue&type=template&id=57c159c0&scoped=true&"
import script from "./TrademarkBanner.vue?vue&type=script&lang=ts&"
export * from "./TrademarkBanner.vue?vue&type=script&lang=ts&"
var disposed = false

function injectStyles (context) {
  if (disposed) return
  
}


  module.hot && module.hot.dispose(function (data) {
    disposed = true
  })

/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "57c159c0",
  null
  ,true
)

/* hot reload */
if (module.hot) {
  var api = require("../../../../../../../../node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57c159c0')) {
      api.createRecord('57c159c0', component.options)
    } else {
      api.reload('57c159c0', component.options)
    }
    module.hot.accept("./TrademarkBanner.vue?vue&type=template&id=57c159c0&scoped=true&", function () {
      api.rerender('57c159c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "desktop/core/src/desktop/js/vue/components/login/TrademarkBanner.vue"
export default component.exports